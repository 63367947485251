<ion-app>
  <ion-menu side="start" menuId="first">
    <ion-header>
      <ion-toolbar color=primary>
        <ion-title style="text-align: center;color:white">Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content style="--background:white">
      <ion-list>
        <div *ngFor="let p of global.Pages">
        <ion-menu-toggle *ngIf="p.ruta!=0">
            <ion-button fill="clear" color="primary" size="default" (click)="OpenPage(p.ruta)"><ion-icon color="secondary" name="caret-forward"> </ion-icon> {{p.title}}</ion-button>
        </ion-menu-toggle> 
        <ion-button *ngIf="p.ruta==0&&p.mostrar!=true" fill="clear" color="primary" size="default" (click)="p.mostrar=!p.mostrar"><ion-icon color="secondary" name="caret-forward"> </ion-icon> {{p.title}}</ion-button>   
        <ion-button *ngIf="p.ruta==0&&p.mostrar==true" fill="clear" color="primary" size="default" (click)="p.mostrar=!p.mostrar"><ion-icon color="secondary" name="caret-down"> </ion-icon> {{p.title}}</ion-button>   
            <div *ngIf="p.mostrar" style="margin-left: 10%">
              <ion-menu-toggle *ngFor="let u of p.menus" >
                <ion-button fill="clear" color="primary" size="default" (click)="OpenPage(u.ruta)"><ion-icon color="secondary" name="caret-forward"> </ion-icon> {{u.title}}</ion-button>
              </ion-menu-toggle>
            </div>
        </div>
        <ion-menu-toggle>
            <ion-img src="assets/imgs/Cs.png" (click)="CerrarSesion()"></ion-img>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
<ion-router-outlet main></ion-router-outlet>
</ion-app>

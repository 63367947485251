import { Injectable } from '@angular/core';
import { ajax } from 'jquery';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
/**
 * @fileoverview servicio global, servicio encargado de conectar con el backend de la app y alimentar de datos los
 *  demas componentes
 * @constructor inyeccion de los servicions y controladores necesarios para el funcionamiento del componente
 *  @param {service} loading llamada del servicio de pantallas de carga
 */

export class GlobalService {
UserData;
FormID;
FormName;
EncuestaName;
EncuestaId;
EncuestaForm;
EncuestaDesc;
EncuestaPos;
NETWORK;
NetCheck;
DatosEncuestas;
Encuestas;
Image;
Sincronizar;
Anexos;
PendienteEncuestas;
UpdateEncuestas;
DatosVisita;
OrigenEncuesta;
Saludo = false;
DatosModificar;
UsuarioM;
Sedes;

Componentes = [
  {Id: 1, Nombre: 'Input', type: ['text', 'number', 'email', 'password', 'tel']},
  {Id: 2, Nombre: 'Select'},
  {Id: 3, Nombre: 'Localización'},
  {Id: 4, Nombre: 'Checkbox'},
  {Id: 6, Nombre: 'Fecha'},
  {Id: 7, Nombre: 'Hora'},
  {Id: 8, Nombre: 'Input Detallado'},
  {Id: 9, Nombre: 'Lista'},
  {Id: 10, Nombre: 'Select Multiple'},
  {Id: 11, Nombre: 'Lista de Fotografias'},
  {Id: 12, Nombre: 'Elementos'},
];
Encuesta;
Pages = [
  {title: 'Página Principal', ruta: '/home'},
  {title: 'Visitas', ruta: 0, menus: [{title: 'Visita 1', ruta: '/select-muni'}, {title: 'Visita 2', ruta: '/selectmuni2'},  {title: 'Visita 3', ruta: '/selectmuni3'}], mostrar: false, online: false},
];
PagesOri = [
  {title: 'Página Principal', ruta: '/home'},
  {title: 'Visitas', ruta: 0, menus: [{title: 'Visita 1', ruta: '/select-muni'}, {title: 'Visita 2', ruta: '/selectmuni2'}, {title: 'Visita 3', ruta: '/selectmuni3'}], mostrar: false, online: false},
];

  constructor(public loading: LoadingService) { }
  CheckInternet() {
    console.log('Checknet');
    const pdata1 = {option: 'inter'};
    ajax({data: pdata1,	cache: false,	dataType: 'json',	type:  'post',
    url: 'https://www.explorandoando.edu.co/DatabaseUIB.php',
    success( data, textStatus, jQxhr ) {
    },
    error( jqXhr, textStatus, errorThrown ) {
    }
    }).then((response) => {
      if (response == 'TRUE') {
        this.NetCheck = true;
      }
    }).catch((err) => {
      console.log('NO INTERNET');
      this.NetCheck = false;
    });
  }

  consultar(datos, respuesta) {
    console.log('Consultando');
    ajax({data: datos,	cache: false,	dataType: 'json',	type:  'post',
    url: 'https://www.explorandoando.edu.co/DatabaseUIB.php',
    success( data, textStatus, jQxhr ) {
      console.warn('bien');
    },
    error( jqXhr, textStatus, errorThrown ) {
      console.warn('mal', datos, textStatus, errorThrown, jqXhr);
    }
    }).then((response) => {
      respuesta(null, response);
    }).catch((err) => {
      respuesta(err);
    });
  }

consultar2(datos, respuesta) {
  console.log('Consultando');
  ajax({data: datos,	cache: false,	dataType: 'json',	type:  'post',
  url: 'https://www.explorandoando.edu.co/DatabaseUIB.php',
  success( data, textStatus, jQxhr ) {
    console.warn('bien');
  },
  error( jqXhr, textStatus, errorThrown ) {
    console.warn('mal');
  }
  }).then((response) => {
    respuesta(null, response);
  }).catch((err) => {
    respuesta(err);
  });
}
}

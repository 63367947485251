import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {
    path: 'apply',
    loadChildren: () => import('./apply/apply.module').then( m => m.ApplyPageModule)
  },
  {
    path: 'buildform',
    loadChildren: () => import('./buildform/buildform.module').then( m => m.BuildformPageModule)
  },
  {
    path: 'buscar',
    loadChildren: () => import('./buscar/buscar.module').then( m => m.BuscarPageModule)
  },
  {
    path: 'cform',
    loadChildren: () => import('./cform/cform.module').then( m => m.CformPageModule)
  },
  {
    path: 'editar',
    loadChildren: () => import('./editar/editar.module').then( m => m.EditarPageModule)
  },
  {
    path: 'edyatr2',
    loadChildren: () => import('./edyatr2/edyatr2.module').then( m => m.Edyatr2PageModule)
  },
  {
    path: 'eform',
    loadChildren: () => import('./eform/eform.module').then( m => m.EformPageModule)
  },
  {
    path: 'egrupos',
    loadChildren: () => import('./egrupos/egrupos.module').then( m => m.EgruposPageModule)
  },
  {
    path: 'encuestas',
    loadChildren: () => import('./encuestas/encuestas.module').then( m => m.EncuestasPageModule)
  },
  {
    path: 'euser',
    loadChildren: () => import('./euser/euser.module').then( m => m.EuserPageModule)
  },
  {
    path: 'evisita',
    loadChildren: () => import('./evisita/evisita.module').then( m => m.EvisitaPageModule)
  },
  {
    path: 'general',
    loadChildren: () => import('./general/general.module').then( m => m.GeneralPageModule)
  },
  {
    path: 'grupos',
    loadChildren: () => import('./grupos/grupos.module').then( m => m.GruposPageModule)
  },
  {
    path: 'lasistencia',
    loadChildren: () => import('./lasistencia/lasistencia.module').then( m => m.LasistenciaPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'nuser',
    loadChildren: () => import('./nuser/nuser.module').then( m => m.NuserPageModule)
  },
  {
    path: 'otro',
    loadChildren: () => import('./otro/otro.module').then( m => m.OtroPageModule)
  },
  {
    path: 'regisvisita',
    loadChildren: () => import('./regisvisita/regisvisita.module').then( m => m.RegisvisitaPageModule)
  },
  {
    path: 'select-muni',
    loadChildren: () => import('./select-muni/select-muni.module').then( m => m.SelectMuniPageModule)
  },
  {
    path: 'selectmuni2',
    loadChildren: () => import('./selectmuni2/selectmuni2.module').then( m => m.Selectmuni2PageModule)
  },
  {
    path: 'selectmuni3',
    loadChildren: () => import('./selectmuni3/selectmuni3.module').then( m => m.Selectmuni3PageModule)
  },
  {
    path: 'temas',
    loadChildren: () => import('./temas/temas.module').then( m => m.TemasPageModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./usuarios/usuarios.module').then( m => m.UsuariosPageModule)
  },
  {
    path: 'visita1',
    loadChildren: () => import('./visita1/visita1.module').then( m => m.Visita1PageModule)
  },
  {
    path: 'visita2',
    loadChildren: () => import('./visita2/visita2.module').then( m => m.Visita2PageModule)
  },
  {
    path: 'visita3',
    loadChildren: () => import('./visita3/visita3.module').then( m => m.Visita3PageModule)
  },
  {
    path: 'formularios',
    loadChildren: () => import('./formularios/formularios.module').then( m => m.FormulariosPageModule)
  },
  {
    path: 'gobernacion',
    loadChildren: () => import('./gobernacion/gobernacion.module').then( m => m.GobernacionPageModule)
  },
  {
    path: 'reportegrupos',
    loadChildren: () => import('./reportegrupos/reportegrupos.module').then( m => m.ReportegruposPageModule)
  },
  {
    path: 'rform',
    loadChildren: () => import('./rform/rform.module').then( m => m.RformPageModule)
  },
  {
    path: 'Municipios',
    loadChildren: () => import('./Municipios/Municipios.module').then( m => m.MunicipiosPageModule)
  },
  {
    path: 'mostrar',
    loadChildren: () => import('./mostrar/mostrar.module').then( m => m.MostrarPageModule)
  },
  {
    path: 'password',
    loadChildren: () => import('./password/password.module').then( m => m.PasswordPageModule)
  },
  {
    path: 'migracion',
    loadChildren: () => import('./migracion/migracion.module').then( m => m.MigracionPageModule)
  },  {
    path: 'sedes',
    loadChildren: () => import('./sedes/sedes.module').then( m => m.SedesPageModule)
  },
  {
    path: 'selectsede',
    loadChildren: () => import('./selectsede/selectsede.module').then( m => m.SelectsedePageModule)
  },
  {
    path: 'sedenew',
    loadChildren: () => import('./sedenew/sedenew.module').then( m => m.SedenewPageModule)
  },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Platform, NavController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController } from '@ionic/angular';
import { GlobalService } from './global.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    public navCtrl: NavController,
    public menuCtrl: MenuController,
    private network: Network,
    public global: GlobalService
  ) {
    this.initializeApp();
  }

  ngOnInit() {
    this.navCtrl.navigateRoot('/login');
  }


  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();

      this.statusBar.styleLightContent();

      this.statusBar.backgroundColorByHexString('410000');

      this.global.CheckInternet();

    });

    // console.log('Network', this.network.type);

    this.global.NETWORK = this.network.type;
  }

  OpenPage(ruta) {
    this.navCtrl.navigateRoot(ruta);

  }

  CerrarSesion() {
    this.global.Pages = this.global.PagesOri;
    this.navCtrl.navigateRoot('/login');
    this.global.Pages = this.global.PagesOri;
    this.global.Saludo = false;
  }
}

